div.select {
  width: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: var(--dark-blue);
  // border-radius: 20px;
  padding: 0px 13px 0 20px;
  letter-spacing: 0.02em;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  letter-spacing: 0.02em;
  padding: 0;
  height: 100%;
}

li.selectItem {
  padding: 7.5px 30px;
  color: var(--dark-blue);
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  transition: 0.2s;
  background-color: white !important;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 27px;
  top: 50%;
  transform: translateY(-50%);
  color: hsla(222, 18%, 75%, 1);
  font-size: 17px;
  width: 24px;
  height: 24px;
  transition: 0.2s;
  pointer-events: none;

  &.open {
    transform: translateY(-50%) rotate(180deg);
  }
}

.menuWrapper {
  border-radius: 0  0 25px 25px !important;
  box-shadow: none !important;
}

.menu {
  background-color: white;
}

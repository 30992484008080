.inventoryItem {
  background-image: linear-gradient(146.92deg, #cf73fa 0%, #6820c4 95.16%);
  border-radius: 25px;
  width: 100%;
  aspect-ratio: 1;
  box-shadow: 0px 3.6px 3.6px rgba(104, 32, 196, 0.05),
    0px 4.5px 13.5px rgba(207, 115, 250, 0.35);
  position: relative;
  padding: 22px 19px 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  z-index: 4;

  @media (max-width: 480px) {
    padding: 22px 18px 8px;
  }

  &.nft_pass {
    background-image: linear-gradient(146.92deg, #cf73fa 0%, #6820c4 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(104, 32, 196, 0.05),
      0px 4.5px 13.5px rgba(207, 115, 250, 0.35);
    .imageWrapper {
      width: 70%;
      height: 53%;
    }
  }
  &.ace_5 {
    background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      linear-gradient(146.92deg, #ffb168 0%, #b12b00 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(197, 210, 232, 0.35);
    .imageWrapper {
      width: 52%;
      height: 53%;
    }
  }
  &.ace_10 {
    background-image: linear-gradient(146.92deg, #cdd9ee 0%, #6578ad 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(197, 210, 232, 0.35);
    .imageWrapper {
      width: 52%;
      height: 53%;
    }
  }
  &.ace_15 {
    background-image: linear-gradient(146.92deg, #ffe999 0%, #f3a100 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(232, 180, 88, 0.35);
    .imageWrapper {
      width: 52%;
      height: 53%;
    }
  }
  &.nft_car_tier_1 {
    background-image: linear-gradient(146.92deg, #ffce70 0.01%, #f06517 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(235, 134, 44, 0.35);
    .imageWrapper {
      width: 59%;
      height: 59%;
    }
  }
  &.nft_box {
    background-image: linear-gradient(146.92deg, #70b6de 0.01%, #154da2 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(89, 142, 189, 0.35);
    .imageWrapper {
      width: 58%;
      height: 54%;
    }
  }
  &.alpha_invite {
    background-image: linear-gradient(146.92deg, #ff9263 0%, #ba1450 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(197, 39, 83, 0.35);
    .imageWrapper {
      top: 57%;
      left: 51%;
      width: 96%;
      height: 92%;
      transform: rotate(4.5deg) translate(-50%, -50%);
    }
    .title {
      max-width: 60%;
    }
  }
  &.refferal_invite {
    background-image: linear-gradient(146.92deg, #ff9263 0%, #ba1450 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(197, 39, 83, 0.35);
    .imageWrapper {
      top: 57%;
      left: 51%;
      width: 96%;
      height: 92%;
      transform: rotate(4.5deg) translate(-50%, -50%);
    }
    .title {
      max-width: 60%;
    }
  }
  &.lootbox_reroll {
    background-image: linear-gradient(146.92deg, #707bde 0.01%, #2915a2 95.16%);
    box-shadow: 0px 3.6px 3.6px rgba(0, 0, 0, 0.05),
      0px 4.5px 13.5px rgba(89, 142, 189, 0.35);
    .imageWrapper {
      width: 48%;
      height: 49%;
    }
  }

  .titleWrapper {
  }

  .title {
    position: relative;
    z-index: 2;
    font-size: 12px;
    font-family: HeadingNow;
    text-transform: uppercase;
    line-height: 125%;
    @media (max-width: 480px) {
      font-size: 9px;
    }
  }

  .multiply {
    font-family: HeadingNow;
    font-size: 35px;
    line-height: 125%;
    opacity: 0.75;
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }

  .code {
    font-size: 10.5px;
    opacity: 0.5;
    @media (max-width: 480px) {
      font-size: 8px;
    }
  }

  .imageWrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &.noShadow {
    box-shadow: none;
  }
}

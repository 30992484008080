.loadingPage {
  width: 100%;
  height: var(--height, 100vh);
  position: relative;
}

.loaderWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logoWrapper {
  width: 98px;
  height: 39.01px;
  @media (max-width: 400px) {
    height: 75.36px;
    height: 30px;
  }
  .logo {
    width: 100%;
    height: 100%;
  }
}

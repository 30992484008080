.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 302px;

  @media (max-width: 920px) {
    min-height: 210px;
  }
}

.selectWrapper {
  width: 100%;
  height: 70px;
  position: relative;

  @media (max-width: 920px) {
    height: 50px;
    font-size: 13px;
  }
}

.loader {
  & > div {
    background-color: white;
  }
}

.submitWrapper {
  font-size: 18px;
  & > button {
    height: 65.48px;
    width: 100%;
  }

  @media (max-width: 920px) {
    font-size: 13px;
    margin-top: 20px;
    & > button {
      height: 45.48px;
    }
  }
}

.textarea {
  resize: none;
  width: 100%;
  border: 1px solid var(--dark-blue);
  border-radius: 25px;
  padding: 30px;
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: var(--dark-blue);
  transition: 0.2s;
  height: 100px;
  overflow: hidden;

  &::placeholder {
    color: var(--dark-blue);
  }

  &:focus {
    border-color: var(--main-color);
  }

  @media (max-width: 920px) {
    height: 80px;
    padding: 15px 30px;
  }
}

.inputs{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttonLink{
  height: 65px;
  width: 100%;
  background-color: var(--telegram);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px var(--telegram);
  }
}
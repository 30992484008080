.welcome {
  width: 100vw;
  height: var(--height, 100vh);
  position: relative;
  user-select: none;
}

.headerContainer {
  z-index: 2;
  position: absolute;
  max-width: 1560px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  padding-top: 46px;
  width: min-content;

  @media (max-width: 920px) {
    padding-top: 22px;
    margin: 0 auto;
  }

  .logoWrapper {
    width: 98px;
    height: 39.01px;
    position: relative;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
}

.authorizationContainer {
  position: relative;
  z-index: 10;
  max-width: 1560px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
}

.authorization {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: end;

  @media (max-width: 920px) {
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 50px;
  }
  @media (max-width: 760px) {
    padding-bottom: 90px;
  }

  .form {
    padding: 109px 98px 108px 98px;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    width: 100%;
    max-width: 596px;
    min-height: 730px;

    @media (max-width: 1100px) {
      padding: 64px 60px;
      max-width: 496px;
      min-height: 650px;
    }
    @media (max-width: 920px) {
      padding: 55px 23.5px 28px 23.5px;
      // max-width: 496px;
      max-width: 390px;
      min-height: 390px;
    }

    .title {
      margin-bottom: 72px;
      max-width: 320px;
      font-family: HeadingNow;
      font-size: 38px;
      line-height: 50px;
      color: var(--darker-blue);
      text-transform: uppercase;
      @media (max-width: 920px) {
        line-height: 25px;
        font-size: 20px;
        margin-bottom: 27.5px;
        max-width: 200px;
      }
    }
  }
}

.background {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  .image {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 30%;
  }
}

.noPage {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: HeadingNow;
  font-size: 26px;
  color: var(--dark-blue);
  gap: 10px;
}

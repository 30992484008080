@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes drawerUp {
  0% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -0%);
  }
  100% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
@keyframes drawerDown {
  0% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  100% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -0%);
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  z-index: -2000;

  &.open {
    width: 100vw;
    height: var(--height, 100vh);
    z-index: 2400;
    display: block;

    @media (min-width: 421px) {
      animation: show;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }

    @media (max-width: 420px) {
      .content {
        animation: drawerUp;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  &.isClosing {
    @media (min-width: 421px) {
      animation: hide;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }

    @media (max-width: 420px) {
      .content {
        animation: drawerDown;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    width: max-content;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 420px) {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  .background {
    z-index: 1;
    background-color: hsla(220, 18%, 51%, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
  }

  &.open {
    .background {
      @media (max-width: 420px) {
        animation: show;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }
  &.isClosing {
    .background {
      @media (max-width: 420px) {
        animation: hide;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.modalWindowWrapper {
  padding: 0 20px;
  width: 686px;
  max-width: 100%;
}

.modalWindow {
  background-color: white;
  border-radius: 30px;
  padding: 10px 10px 0 10px;

  .summery {
    position: relative;
    background-color: var(--twitter);
    color: white;
    padding: 30px 35px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05),
      0px 5px 24px rgba(62, 186, 235, 0.5);

    .close {
      position: absolute;
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
      color: hsla(0, 0%, 100%, 0.5);
      background-color: transparent;
      font-size: 18px;
    }

    .title {
      font-family: HeadingNow;
      font-size: 16px;
      max-width: 340px;
      text-transform: uppercase;
    }
  }
  .content {
    padding: 42px 32px 36px 32px;
    display: flex;
    flex-direction: column;
    gap: 38px;

    @media (max-width: 480px) {
      padding: 22px 22px 34px 22px;
    }

    .text {
      font-weight: 700;
      font-size: 20px;
      line-height: 125%;
      color: var(--dark-blue);

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
  }

  .connect {
    background-color: var(--twitter);
    text-transform: uppercase;
    font-size: 15px;
    font-family: HeadingNow;
    max-width: 200px;
    height: 45px;
    color: white;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    
    .icon{
      font-size: 20px;
    }
  }

  &.twitter {
    .summery {
      background-color: var(--twitter);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05),
        0px 5px 24px var(--twitter-opacity);
    }
    .connect {
      background-color: var(--twitter);
    }
  }
  &.telegram {
    .summery {
      background-color: var(--telegram);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05),
        0px 5px 24px var(--telegram-opacity);
    }
    .connect {
      background-color: var(--telegram);
    }
  }
  &.discord {
    .summery {
      background-color: var(--discord);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05),
        0px 5px 24px var(--discord-opacity);
    }
    .connect {
      background-color: var(--discord);
    }
  }
}

.earnedContainer {
  user-select: none;
  background-color: white;
  border-radius: 25px;
  padding: 27px 18px 24px 35px;
  position: relative;
  min-height: 246px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 650px) {
    padding: 22px 0px 13px 0px;
    justify-content: space-between;
  }

  .additionalText {
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    color: hsla(220, 32%, 74%, 1);
    max-width: 90%;

    @media (max-width: 650px) {
      padding: 0 13px;
      position: absolute;
      bottom: 38px;
      left: 0;
    }
    @media (max-width: 480px) {
      max-width: 90%;
      font-size: 14px;
      line-height: 125%;
      opacity: 0.5;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 26px;
    width: 100%;

    @media (max-width: 650px) {
      gap: 14px;
    }
  }

  .topPart {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 650px) {
      padding: 0 13px;
    }
  }

  .title {
    font-family: HeadingNow;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark-blue);
    white-space: nowrap;

    @media (max-width: 480px) {
      font-size: 13px;
    }
  }

  .currentBalance {
    color: var(--dark-blue);
    font-weight: 800;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    white-space: nowrap;

    .balanceIcon {
      font-size: 23px;
      color: var(--main-color);
    }

    @media (max-width: 480px) {
      font-size: 15px;
      .balanceIcon {
        font-size: 14px;
      }
    }
  }

  .giftWrapper {
    @media (max-width: 650px) {
      display: none;
    }
  }

  .giftWrapperMobile {
  }

  .progressBarWrapper {
    padding-top: 7.5px;
    width: 100%;
    z-index: 3;

    @media (max-width: 650px) {
      display: none;
    }
  }

  .scrollArea {
    max-width: calc(100vw - 40px);

    @media (min-width: 651px) {
      display: none;
    }
  }

  .scrollContent {
    padding: 10px 13px 70px 13px;
    min-width: 526px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 480px) {
      min-width: 480px;
    }
  }
}

.missionContent {
  display: flex;
  flex-direction: column;
  padding: 49px 29px 50px 35px;

  @media (max-width: 480px) {
    padding: 30px 25px 37px 25px;
  }
}



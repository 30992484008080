.snackBar {
  position: relative;
  padding: 24px 30px 24px 34px;
  border-radius: 30px;
  width: 334px;
  max-width: 100%;
  background-color: white;
  color: hsla(220, 18%, 51%, 1);

  &.error {
    background-color: hsla(0, 100%, 70%, 1);
    color: hsla(220, 23%, 97%, 1);

    .closeButton {
      color: hsla(0, 0%, 100%, 0.5);
    }
  }

  @media (max-width: 480px) {
    padding: 16px 50px 14px 26px;
    width: 263px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), 0px 2.26364px 3.77273px rgba(49, 62, 72, 0.03);
    border-radius: 22px;

    .closeButton{
      top: 12px;
      right: 12px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    font-family: HeadingNow;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    @media (max-width: 480px) {
      font-size: 11px;
    }
  }

  .message {
    font-family: Manrope;
    font-weight: 600;
    font-size: 15px;
    line-height: 125%;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.closeButton {
  position: absolute;
  background-color: transparent;
  border: none;
  color: hsla(220, 18%, 51%, 0.5);
  font-size: 12px;
  top: 25px;
  right: 21px;
}

:root {
  --main-color: hsla(32, 100%, 62%, 1);
  --main-color-opacity: hsla(32, 100%, 62%, 0.5);
  --main-color-opacity2: hsla(32, 100%, 62%, 0.1);
  --golden: hsla(38, 99%, 69%, 1);
  --bg-color: hsla(220, 23%, 97%, 1);
  --dark-color: hsla(0, 0%, 31%, 1);
  --light-color: hsla(0, 0%, 100%, 0.85);
  --dark-blue: hsla(220, 18%, 51%, 1);
  --darker-blue: hsla(220, 19%, 38%, 1);
  --dark-blue-opacity: hsla(220, 18%, 51%, 0.5);
  --blue: hsla(197, 84%, 61%, 1);
  --check-blue: hsla(197, 81%, 58%, 1);
  --check-blue-opacity: hsla(197, 81%, 58%, 0.1);
  --twitter: hsla(197, 81%, 58%, 1);
  --twitter-opacity: hsla(197, 81%, 58%, 0.5);
  --discord: hsla(248, 69%, 56%, 1);
  --discord-opacity: hsla(248, 69%, 56%, 0.5);
  --telegram: hsla(216, 84%, 63%, 1);
  --telegram-opacity: hsla(216, 84%, 63%, 0.5);
  --gray: hsla(220, 12%, 91%, 1);
  --range-color: hsla(220, 18%, 51%, 0.1);
}

/* ---nullification--- */
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  font-family: Manrope;
  overflow-y: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
input,
button,
textarea {
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  background-color: transparent;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
  user-select: none;
  -webkit-user-drag: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
/* ::-webkit-scrollbar {display: none;} */
/* ---end nullification--- */

.fill {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.link {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.link-text {
  color: inherit;
}

$primary-color: var(--dark-blue);
@import "loaders.css/src/animations/line-scale-pulse-out-rapid.scss";

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.orangeBalance {
  color: var(--main-color);
  font-size: 23px;

  @media (max-width: 480px) {
    font-size: 19px;
  }
}

.snackBarContainer {
  @media (max-width: 920px) {
    bottom: 90px !important;
  }
}

.won .swiper-slide-active > div {
  transform: translateX(-50%) translateY(10%) scale(1.2) !important;
  z-index: 4;
}

.won .swiper-slide-active > div::after {
  position: absolute;
  content: "";
  width: 150vw;
  left: 50%;
  top: 50%;
  height: 100vh;

  transform: translate(-50%, -50%);

  backdrop-filter: blur(3px);
  background-color: hsla(270, 80%, 25%, 0.15);
  z-index: 1;
  transition: 0.3s;
  animation: displaying 0.3s, sizing 0.3s;
}

@keyframes displaying {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sizing {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 150vw;
    height: 100vh;
  }
}


.mobileBottom {
  display: none;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  background-color: white;

  @media (max-width: 920px) {
    display: block;
    z-index: 2000;
  }

  .container {
    max-width: 590px;
    padding: 0 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media (max-width: 390px) {
      padding: 0 20px;
    }
  }

  .navElement {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      width: 25px;
      height: 25px;
      color: var(--dark-blue);
      transition: 0.3s;
    }

    .title {
      font-family: HeadingNow;
      font-size: 10px;
      color: var(--dark-blue);
      text-transform: uppercase;

      @media (max-width: 440px) {
        font-size: 6px;
      }
    }

    &.active {
      .icon {
        color: var(--main-color);
      }
    }
  }
}

.drawerWrapper {
  .drawerButton {
    width: 25px;
    height: 25px;
    position: relative;
    background-color: transparent;

    .drawerLine {
      position: absolute;
      background-color: var(--dark-blue);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 3px;
      width: 90%;
      border-radius: 2px;
      transition: 0.1s;

      &:nth-child(1) {
        top: 21%;
      }
      &:nth-child(3) {
        top: 80%;
      }
    }

    &.active {
      .drawerLine {
        &:nth-child(1) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-child(3) {
          transform: translate(-50%, -50%) rotate(-45deg);
          top: 50%;
        }
      }
    }
  }
}

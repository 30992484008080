.reward {
  user-select: none;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;

  @media (max-width: 480px) {
    gap: 5px;
  }

  .balanceIcon {
    color: var(--golden);
    font-size: 22px;

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  .ace {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: var(--dark-blue);
    white-space: nowrap;

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }

  .plus {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: var(--golden);

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
}

@use "/src/styles/variables" as v;

.layout {
  height: var(--height, 100vh);
  width: 100vw;
  overflow: hidden;
  background-color: var(--bg-color);
}

.content {
  height: calc(var(--height, 100vh) - v.$headerHeight);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 50px 20px 30px 20px;
  display: grid;
  grid-template-columns:
    1fr minmax(286px, 334px) minmax(3%, 82px) minmax(614px, 687px)
    minmax(3%, 82px) minmax(286px, 334px) 1fr;
  grid-template-areas: "leftSpace sideNav closeLeftSpace main closeRightSpace sideBan rightSpace";
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1310px) {
    grid-template-columns: 1fr minmax(286px, 334px) 3% minmax(614px, 687px) 1fr;
    grid-template-areas: "leftSpace sideNav closeLeftSpace main";
  }

  @media (max-width: 980px) {
    padding: 30px 20px 30px 20px;
  }
  @media (max-width: 970px) {
    grid-template-columns: 1fr minmax(256px, 334px) 3% minmax(614px, 687px) 1fr;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr minmax(256px, 334px) 3% minmax(580px, 687px) 1fr;
  }
  @media (max-width: 920px) {
    grid-template-columns: 1fr minmax(200px, 687px) 1fr;
    grid-template-areas: "leftSpace main rightSpace";
    height: calc(var(--height, 100vh) - v.$headerHeightTablet);
  }
  @media (max-width: 400px) {
    height: calc(var(--height, 100vh) - v.$headerHeightMobile);
  }
}

.leftSide {
  grid-area: sideNav;
  top: 0px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 920px) {
    display: none;
  }

  .sideBannersMobile {
    display: none;

    @media (max-width: 1310px) {
      display: block;
    }
  }
}

.middlePart {
  grid-area: main;

  @media (max-width: 920px) {
    padding-bottom: 85px;
  }
}
.rightSide {
  grid-area: sideBan;
  top: 0px;
  height: min-content;
  position: -webkit-sticky; /* Safari */
  position: sticky;

  @media (max-width: 1310px) {
    display: none;
  }
}

.FAQ {
  position: fixed;
  bottom: 43.5px;
  right: 43.5px;
  z-index: 5;

  @media (max-width: 920px) {
    display: none;
  }

  .FAQButton {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 67.5px;
    height: 67.5px;
    background-color: white;
    border-radius: 50%;
    color: var(--main-color);
    font-weight: 800;
    font-size: 35.9999px;
    transition: 0.3s;
    user-select: none;

    &:hover {
      transform: translateY(-2px);
    }
    &:active {
      transform: translateY(-2px);
    }
  }
  .FAQForm {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    max-width: 496px;
    background-color: white;
    transition: 0.3s;
    border-radius: 30px;

    pointer-events: none;
    opacity: 0;
    z-index: -1000;

    display: flex;
    flex-direction: column;
    padding: 36.5px 37px 48px 36px;
    gap: 34px;

    &.open {
      opacity: 1;
      z-index: 1000;
      pointer-events: all;
    }

    .topPart {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20.5px;

      .title {
        font-family: "HeadingNow";
        font-size: 20px;
        color: var(--dark-blue);
      }

      .question {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 67.5px;
        height: 67.5px;
        border-radius: 50%;
        font-weight: 800;
        font-size: 35.9999px;
        color: var(--dark-blue);
        background-color: hsla(220, 18%, 51%, 0.1);
        user-select: none;
      }
    }

    .close {
      position: absolute;
      top: 30px;
      right: 38px;
      background-color: transparent;
      color: hsla(220, 18%, 51%, 0.5);
      font-size: 20px;
    }
  }
}

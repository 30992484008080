.inputWrapper {
  width: 100%;
  position: relative;

  .input {
    width: 100%;
    background-color: white;
    border-radius: 25px;
    border: 1px solid var(--main-color);
    padding-left: 30px;
    padding-right: 23px;
    height: 70px;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-blue);

    @media (max-width: 920px) {
      height: 45px;
      border-radius: 18.75px;
      font-size: 13px;
    }
  }

  .iconWrapper {
    position: absolute;
    color: var(--dark-blue);
    opacity: 0.5;
    font-size: 22px;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.loader {
  & > div {
    background-color: white;
  }
}

.submitWrapper {
  margin-top: 30px;
  & > button {
    height: 65.48px;
    width: 100%;
  }

  @media (max-width: 920px) {
    margin-top: 20px;
    & > button {
      height: 45.48px;
    }
  }
}

.separator {
  padding-top: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--dark-blue);

  @media (max-width: 920px) {
    padding-top: 20px;
  }

  .line {
    height: 2px;
    width: 100%;
    max-width: 160px;
    background-color: hsla(0, 0%, 40%, 0.1);
  }
}

.googleButtonWrapper {
  padding-top: 37px;

  @media (max-width: 920px) {
    padding-top: 20px;
  }

  .googleButton {
    transition: 0.3s;
    width: 100%;
    height: 65.45px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-blue);
    background-color: white;

    & > span {
      font-size: 24px;
    }

    @media (min-width: 787px) {
      &:hover {
        transform: translateY(-2px);
      }
    }
    @media (max-width: 920px) {
      font-size: 12px;
      height: 45px;
      border-radius: 18.75px;
      & > span {
        font-size: 20px;
      }
    }
  }
}

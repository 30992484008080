$tablet: 770px;
$mobile: 420px;

.mission {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 40px;
    border-bottom: 2px solid hsla(206, 19%, 24%, 0.05);

    @media (max-width: 480px) {
      padding-bottom: 30px;
    }
  }

  &:not(:first-child) {
    padding-top: 40px;
    @media (max-width: 480px) {
      padding-top: 30px;
    }
  }

  .check {
    min-width: 50px;
    height: 50px;
    border-radius: 18px;
    background-color: var(--range-color);
    color: var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;

    &.alternative {
      background-color: var(--check-blue-opacity);
      color: var(--check-blue);
    }
  }

  .topPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .title {
    font-family: HeadingNow;
    font-size: 16px;
    line-height: 125%;
    color: var(--dark-blue);
    text-transform: uppercase;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 125%;
    color: var(--dark-blue);

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 40px;
    flex-wrap: wrap;

    @media (max-width: 480px) {
      margin-top: 20px;
      column-gap: 10px;
      row-gap: 20px;
    }

    .buttonWrapper {
      height: 50px;
      font-size: 13px;
      background-color: transparent;
      border: none;
      @media (max-width: 480px) {
        // height: 40px;
      }
    }

    .checkButton {
      user-select: none;
      font-family: HeadingNow;
      font-size: 13px;
      text-transform: uppercase;
      color: var(--dark-blue);
      border: 2px solid var(--dark-blue);
      padding: 0 24px;
      background-color: transparent;
      border-radius: 25px;
      transition: 0.3s;
      box-shadow: 0px 5px 15px transparent;
      height: 50px;
      opacity: 0.5;
      cursor: default;

      @media (max-width: 480px) {
        height: 40px;
        font-size: 10px;
      }

      &.afterTask {
        opacity: 1;
        cursor: pointer;

        @media (min-width: 770px) {
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 5px 15px var(--dark-blue-opacity);
          }
        }
      }
    }
  }

  &.completed {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 23px;
    }

    .leftPart {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    .text {
      color: var(--dark-blue-opacity);
    }
  }
}

.images {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}

.imageWrapper {
  width: 150px;
  aspect-ratio: 1;
  position: relative;
  border-radius: 25px;
  background-color: rgba(107, 122, 153, 0.1);
  overflow: hidden;

  img {
    object-fit: cover;
  }

  .close {
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 13px;
    color: white;
  }
}

.button {
  font-size: 13px;
}

.submit {
  font-size: 13px;
}

.buttonInput {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HeadingNow;
  text-transform: uppercase;
  color: white;
  height: 50px;
  max-height: 100%;
  background-color: var(--main-color);
  border-radius: 25px;
  padding: 0 31px;
  width: min-content;
  transition: 0.3s;
  box-shadow: 0px 5px 15px transparent;
  position: relative;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px rgba(255, 165, 59, 0.5);
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0px 5px 15px transparent;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

.fileInput {
  display: none;
}

.inputText {
  width: 100%;
  padding: 0 27px;
  border-radius: 25px;
  font-weight: 600;
  font-size: 15px;
  color: var(--dark-blue);
  border: 1px solid rgba(181, 188, 204, 0.5);
  margin-top: 25px;
  height: 60px;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::placeholder {
    color: var(--dark-blue);
    font-size: 15px;
  }

  @media (max-width: $tablet) {
    font-size: 13px;
    height: 40px;
  }
}

.inputLongText {
  resize: none;
  padding: 28px 27px 27px;
  min-height: 131px;

  @media (max-width: $tablet) {
    padding: 20px 15px 20px;
  }
}

.missionsPage {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 950px) {
    gap: 20px;
  }
}

.accordions {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.timerWrapper{
  @media (min-width: 1311px) {
    display: none;
  }
}

.missionContent {
  display: flex;
  flex-direction: column;
  padding: 49px 29px 50px 35px;

  @media (max-width: 480px) {
    padding: 30px 25px 37px 25px;
  }

  .logged {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-bottom: 30px;
    align-items: center;
    border-bottom: 1px solid hsla(206, 19%, 24%, 0.05);

    .iconWrapper {
      min-width: 50px;
      height: 50px;
      border-radius: 18px;
      background-color: var(--check-blue-opacity);
      color: var(--check-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
    }

    .text {
      font-weight: 700;
      font-size: 17px;
      line-height: 125%;
      color: var(--dark-blue);
    }
  }
}


.FAQPage {
  display: flex;
  flex-direction: column;
  gap: 41px;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 40px;
}

.FAQSummery {
  background-color: var(--dark-blue);
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;
  border-radius: 20px;
  padding: 25px 18px;

  .title {
    font-family: HeadingNow;
    text-transform: uppercase;
    font-size: 13px;
    color: white;
  }

  .questionIcon{
    background-color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: var(--dark-blue);
    font-size: 19px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.FAQWrapper{
    padding: 0 5px;
}

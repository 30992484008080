.timer {
  background-image: linear-gradient(180deg, #a25cea 0%, #7835bd 100%);
  padding: 25px 24px 14px;
  border-radius: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 21px;

  .title {
    font-family: HeadingNow;
    font-size: 18px;
    text-transform: uppercase;
    color: white;
  }

  .bottomPart {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .container {
    background-image: linear-gradient(0deg, #6312b7 0%, rgba(0, 0, 0, 0) 100%);
    filter: drop-shadow(0px 10px 20px rgba(25, 0, 36, 0.5));
    height: 60px;
    border-radius: 25px;
    border: 1px solid hsla(270, 100%, 83%, 0.4);
    border-bottom: 0.5px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;

    .innerContainer {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      max-width: 194px;
      margin: 0 auto;
      z-index: 3;
      position: relative;

      font-family: HeadingNow;
      color: hsla(32, 100%, 50%, 1);
    }

    .separator {
      font-size: 10px;
    }

    .time {
      font-size: 20px;
      text-shadow: -1px -1px 8px hsla(38, 100%, 86%, 0.4);
      background: linear-gradient(
        180deg,
        rgba(255, 224, 176, 0.85) 0%,
        #ff8a00 100%
      );
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: 42px;
      overflow: visible;
      text-align: center;
    }

    @mixin fill {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      border-radius: 25px;
    }

    .background {
      @include fill();
      background-image: linear-gradient(
        147.42deg,
        rgba(255, 255, 255, 0.25) -0.91%,
        rgba(255, 255, 255, 0) 47.38%,
        rgba(255, 255, 255, 0.125) 94.67%
      );
      z-index: 1;
    }
    .background2 {
      @include fill();
      background-image: linear-gradient(
        0deg,
        #6312b7 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
  }

  .labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 194px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.02em;
    opacity: 0.5;
    color: #ffffff;
    margin: 0 auto;
  }
}

$tablet: 770px;
$mobile: 420px;

.modalWindowWrapper {
  padding: 0 20px;
  width: 924px;
  max-width: 100%;

  @media (max-width: $tablet) {
    padding: 0 10px;
  }
  @media (max-width: $mobile) {
    padding: 0;
  }
}

.modalWindow {
  width: 100%;
  background: linear-gradient(136.62deg, #a761ed 10.98%, #7741ad 87.18%);
  border-radius: 30px;
  overflow: hidden;
  user-select: none;
  position: relative;

  @media (max-width: $mobile) {
    border-radius: 30px 30px 0 0;
  }

  .summeryWrapper {
    padding: 10px 10px 0 10px;

    @media (max-width: $tablet) {
      padding: 5px 5px 0 5px;
    }
  }

  .summery {
    position: relative;
    background: linear-gradient(90.65deg, #fec360 4.73%, #ffa53b 87.8%);
    box-shadow: 0px 5px 25px rgba(254, 195, 96, 0.75),
      0px 5px 15px rgba(0, 0, 0, 0.25);
    color: white;
    padding: 0 35px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 88px;
    z-index: 5;

    @media (max-width: $tablet) {
      padding: 0 14px;
      min-height: 75px;
    }

    .close {
      position: absolute;
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
      color: hsla(0, 0%, 100%, 0.5);
      background-color: transparent;
      font-size: 18px;

      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }

    .title {
      font-family: HeadingNow;
      font-size: 16px;
      max-width: 90%;
      text-transform: uppercase;

      @media (max-width: $tablet) {
        max-width: 80%;
        font-size: 13px;
      }
    }
  }

  .content {
    padding: 41.5px 0 37px 0;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile) {
      padding-bottom: 180px;
    }

    .title {
      padding: 0 44px;
      font-family: HeadingNow;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      position: relative;
      z-index: 5;

      @media (max-width: $mobile) {
        padding: 0 40px;
        font-size: 15px;
      }
    }

    .button {
      position: relative;
      margin: 0 44px;
      margin-top: 87px;
      z-index: 5;
      width: 169px;

      @media (max-width: $mobile) {
        margin: 0 auto;
        margin-top: 50px;
      }
    }

    .imageWrapper {
      position: absolute;
      width: 324px;
      height: 343px;
      right: 4.7%;
      top: 100%;
      transform: translateY(-45%);
      z-index: 5;

      @media (max-width: $mobile) {
        right: initial;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.swiperWrapper {
  margin-top: 40px;
  position: relative;
  z-index: 4;
  // pointer-events: none;

  .swiper {
    overflow: visible;
  }

  .slide {
    aspect-ratio: 1;

    .slideContent {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      transition: 0.3s;
    }
  }

  .item {
    max-height: 210px;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.5);
    &.red {
      background: linear-gradient(146.92deg, #ff9263 0%, #ba1414 95.16%);
    }
    &.blue {
      background: linear-gradient(146.92deg, #8d70de 0.01%, #4215a2 95.16%);
    }
    &.silver {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .shadow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: hsla(266, 76%, 24%, 1);
    opacity: 0.6;
    filter: blur(37.5px);
    z-index: 0;
  }
}

.loaderWrapper {
  width: 120px;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  & > div {
    background-color: white;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  border-radius: 30px;
  z-index: 3;
  animation: displaying 0.3s;
}

@keyframes displaying {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sideNavigation {
  background-color: white;
  border-radius: 30px;
  padding: 39px 17px 40px 17px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1310px) {
    padding: 35px 15px 35px 15px;
    gap: 20px;
  }
  @media (max-width: 980px) {
    padding: 25px 15px 25px 15px;
    gap: 10px;
  }

  .navigation {
    position: relative;
    height: 78px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
    transition: 0.3s;
    background-color: white;
    text-align: start;
    transition-property: transform background-color;

    @media (max-width: 980px) {
      padding: 0 15px;
      gap: 10px;
    }
    @media (max-width: 920px) {
      padding: 0 21px;
      gap: 13px;
      height: 60px;
      border-radius: 20px;
    }

    .link {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .iconWrapper {
      color: var(--dark-blue-opacity);
      font-size: 25px;
      position: relative;

      span{
        transition: 0.2s;
      }

      .lock {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
        opacity: 0;
      }

      @media (max-width: 980px) {
        font-size: 22px;
      }
    }

    .title {
      max-width: 174px;
      font-family: HeadingNow;
      font-size: 15px;
      line-height: 18px;
      color: var(--dark-blue);
      text-transform: uppercase;
      user-select: none;

      @media (max-width: 980px) {
        font-size: 14px;
      }
      @media (max-width: 920px) {
        font-size: 13px;
        line-height: 15px;
        max-width: unset;
      }
    }

    @media (min-width: 770px) {
      &:hover {
        background-color: hsla(220, 18%, 51%, 0.1);
        transform: translateY(-1px);

        .lock {
          opacity: 1;
        }

        .icon {
          opacity: 0;
        }
      }
    }

    &.grayIcon {
      .iconWrapper {
        color: var(--dark-blue-opacity);
      }
    }

    &.active {
      background-color: var(--main-color);
      transform: translateY(-1px);

      .title {
        color: white;
      }
      .iconWrapper {
        color: hsla(0, 0, 100%, 0.5);
        & > [data-question="true"] {
          background-color: hsla(0, 0, 100%, 0.5);
        }
      }
      @media (min-width: 770px) {
        &:hover {
          box-shadow: 0px 5px 20px var(--main-color-opacity);
          transform: translateY(-2px);
          .iconWrapper {
            color: white;
          }
        }
      }
    }
  }
}

@use "/src/styles/variables" as v;

.drawerContent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 400px;

  .drawerSpaceTop {
    height: v.$headerHeight;
    @media (max-width: 920px) {
      height: v.$headerHeightTablet;
    }
    @media (max-width: 400px) {
      height: v.$headerHeightMobile;
    }
  }
  .drawerWrapper {
    padding: 23px 20px;
    max-height: calc(var(--height, 100vh) - v.$headerHeightTablet);
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 920px) {
      max-height: calc(var(--height, 100vh) - v.$headerHeightTablet - v.$mobileBottom);
    }
    @media (max-width: 400px) {
      max-height: calc(var(--height, 100vh) - v.$headerHeightMobile);
    }
  }
  .drawerSpaceBottom {
    @media (max-width: 920px) {
      height: v.$mobileBottom;
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;

    .avatarWrapper {
      width: 75px;
      min-width: 75px;
      height: 75px;
      border-radius: 40%;
      overflow: hidden;

      .avatar {
        width: 100%;
        height: 100%;
      }
    }

    .userNameWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .name {
        font-family: HeadingNow;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--dark-blue);
      }
      .email {
        font-weight: 700;
        font-size: 15px;
        color: var(--dark-blue);
      }
    }
  }

  .metaMaskWrapper {
    margin-top: 30px;
  }

  .drawerNavigationWrapper {
    background-color: transparent;
    width: 100%;
    margin: 40px 0;
    .drawerNavigation {
      padding: 0;
    }
  }

  .drawerBannersWrapper {
    background-color: transparent;
    width: 100%;
    .drawerBanners {
      gap: 25px;
    }
  }
}

.questionIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue-opacity);
  color: white;
  font-weight: 800;
  font-size: 19px;
}

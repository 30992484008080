.inventoryPage {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 41px 32px 45px 34px;

  @media (max-width: 480px) {
    padding: 29px 20px;
  }

  .title {
    font-family: HeadingNow;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark-blue);

    @media (max-width: 480px) {
      font-size: 13px;
    }
  }

  .controlPanel {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .switcher {
    width: 100%;
    max-width: 350px;
    height: 50px;

    @media (max-width: 650px) {
      max-width: initial;
    }
    @media (max-width: 480px) {
      height: 40px;
    }
  }

  .claim {
    height: 50px;
    padding: 0 20px;
    background-color: hsla(200, 12%, 95%, 1);
    color: hsla(220, 18%, 51%, 1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    font-weight: 700;
    font-size: 12px;
    font-family: HeadingNow;
    text-transform: uppercase;
    z-index: 10;

    .iconWrapper {
      font-size: 19px;
    }

    @media (max-width: 650px) {
      position: fixed;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .inventoryGrid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .item {
      aspect-ratio: 1;
      width: 100%;
    }

    @media (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      margin-top: 32px;
    }
  }
}

$tablet: 770px;
$mobile: 420px;

.modalWindowWrapper {
  padding: 0 20px;
  width: 726px;
  max-width: 100%;

  @media (max-width: $tablet) {
    padding: 0 ;
    width: 400px;
  }
}

.modalWindow {
  width: 100%;
  background-color: hsla(220, 23%, 97%, 1);
  border-radius: 30px;
  overflow: hidden;
  user-select: none;

  .summeryWrapper {
    padding: 10px 10px 0 10px;

    @media (max-width: $tablet) {
      padding: 5px 5px 0 5px;
    }
  }

  .summery {
    position: relative;
    background-color: var(--main-color);
    color: white;
    padding: 0 35px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05),
      0px 5px 24px rgba(255, 165, 59, 0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 88px;

    @media (max-width: $tablet) {
      padding: 0 14px;
      min-height: 75px;
    }

    .close {
      position: absolute;
      right: 23px;
      top: 50%;
      transform: translateY(-50%);
      color: hsla(0, 0%, 100%, 0.5);
      background-color: transparent;
      font-size: 18px;

      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }

    .title {
      font-family: HeadingNow;
      font-size: 16px;
      max-width: 90%;
      text-transform: uppercase;

      @media (max-width: $tablet) {
        max-width: 80%;
        font-size: 13px;
      }
    }
  }
  .content {
    margin: 40px 43px 71px 43px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 316px;

    @media (max-width: $tablet) {
      margin: 30px 19px 71px 19px;
      min-height: 334px;
      justify-content: space-between;
    }

    .title {
      font-family: HeadingNow;
      font-size: 16px;
      color: var(--dark-blue);
      text-transform: uppercase;
      max-width: 90%;

      @media (max-width: $tablet) {
        font-size: 12px;
        max-width: 90%;
      }
    }

    .description {
      font-weight: 600;
      font-size: 18px;
      line-height: 125%;
      color: var(--dark-blue);
      max-width: 90%;

      @media (max-width: $tablet) {
        font-size: 15px;
        max-width: 100%;
      }
    }

    .bottomPart {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      @media (max-width: $tablet) {
        justify-content: center;
        grid-row: 4 / 5;
      }

      @mixin arrow() {
        background-color: transparent;
        font-size: 17px;
        color: hsla(220, 18%, 51%, 1);
        padding: 5px;
        transition: 0.3s;

        @media (max-width: $tablet) {
          display: none;
        }
      }

      .arrowPrev {
        @include arrow();
        transform: rotate(90deg);
      }
      .arrowNext {
        @include arrow();
        transform: rotate(-90deg);
      }
    }
  }
}

.imgWrapper {
  width: 100%;
  height: 100%;
  max-width: 508px;

  @media (max-width: $tablet) {
    display: none;
  }
}

.imgWrapperMobile {
  width: 100%;
  height: 100%;
  display: none;

  @media (max-width: $tablet) {
    display: block;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bulletActive {
  background-color: var(--main-color) !important;
}

.pagination {
  bottom: 37px !important;
}
.summery {
  height: 92px !important;
  border-radius: 25px !important;
  flex-direction: row;
  padding-right: 24px !important;
  overflow: hidden !important;

  &.completed {
    padding-right: 0px !important;
  }

  @media (max-width: 480px) {
    height: 75px !important;
    padding-right: 15.33px !important;
    padding-left: 15px !important;

    &.completed {
      padding-right: 10px !important;
    }
  }
}

.summeryContent {
  color: white;
  font-family: HeadingNow;
  font-size: 15px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  height: 100%;
  width: 100%;

  @media (max-width: 480px) {
    padding: 0;
    gap: 21px;
    font-size: 13px;
  }

  .title {
    position: relative;
    // z-index: 5;
  }
}

.iconWrapper {
  position: relative;
  font-size: 20px;

  @media (max-width: 625px) {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.visibleIcon {
  position: relative;
  color: white;
  // z-index: 2;

  @media (max-width: 625px) {
    display: none;
  }
}

.iconBackground {
  position: absolute;
  // z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  opacity: 0.2;
  & > span {
    text-shadow: -0 -2px 0 rgba(255, 255, 255, 0.2),
      0 -2px 0 rgba(255, 255, 255, 0.2), -0 2px 0 rgba(255, 255, 255, 0.2),
      0 2px 0 rgba(255, 255, 255, 0.2), -2px -0 0 rgba(255, 255, 255, 0.2),
      2px -0 0 rgba(255, 255, 255, 0.2), -2px 0 0 rgba(255, 255, 255, 0.2),
      2px 0 0 rgba(255, 255, 255, 0.2), -1px -2px 0 rgba(255, 255, 255, 0.2),
      1px -2px 0 rgba(255, 255, 255, 0.2), -1px 2px 0 rgba(255, 255, 255, 0.2),
      1px 2px 0 rgba(255, 255, 255, 0.2), -2px -1px 0 rgba(255, 255, 255, 0.2),
      2px -1px 0 rgba(255, 255, 255, 0.2), -2px 1px 0 rgba(255, 255, 255, 0.2),
      2px 1px 0 rgba(255, 255, 255, 0.2), -2px -2px 0 rgba(255, 255, 255, 0.2),
      2px -2px 0 rgba(255, 255, 255, 0.2), -2px 2px 0 rgba(255, 255, 255, 0.2),
      2px 2px 0 rgba(255, 255, 255, 0.2), -2px -2px 0 rgba(255, 255, 255, 0.2),
      2px -2px 0 rgba(255, 255, 255, 0.2), -2px 2px 0 rgba(255, 255, 255, 0.2),
      2px 2px 0 rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 480px) {
    font-size: 100px;
  }
}

.expandIcon {
  color: white;
  margin-left: auto;
  transition: 0.2s;

  &.expanded {
    transform: rotate(180deg);
  }
}

.leftPart {
  display: flex;
  flex-direction: row;
  gap: 63px;
  align-items: center;
}

.rightPart {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.rewardWrapper {
  background-color: white;
  padding: 0 11px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-family: Manrope;
  font-weight: 800;

  @media (max-width: 625px) {
    height: 27px;
    padding: 0 6px;

    .reward {
      gap: 3px;
    }
    .ace {
      font-size: 9.5px;
      line-height: 15px;
    }
    .icon {
      font-size: 14px;
    }
    .plus {
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.check {
  min-width: 68px;
  height: 68px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 100%, 0.15);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-left: auto;

  @media (max-width: 480px) {
    min-width: 48px;
    height: 48px;
    font-size: 20px;
    border-radius: 15px;
  }
}

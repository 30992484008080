.profilePage{
  padding-bottom: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 47px 57px 56px 47px;

  @media (max-width: 480px) {
    padding: 30px 25px 37px 25px;
    gap: 30px;
  }
  .row {
    display: flex;
    flex-direction: column;
    .title {
      font-family: HeadingNow;
      font-size: 15px;
      color: var(--dark-blue);
      text-transform: uppercase;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
    .input {
      margin-top: 25px;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      color: var(--dark-blue);
      border: 1px solid var(--dark-blue-opacity);
      border-radius: 20px;
      height: 45px;
      padding: 0px 13px 0 20px;
      max-width: 417px;
      letter-spacing: 0.02em;

      @media (max-width: 480px) {
        margin-top: 15px;
        height: 40px;
      }
    }
    .selectWrapper{
      margin-top: 25px;
      width: 100%;
    }
    .buttonWrapper {
      margin-top: 20px;
      font-size: 13px;

      @media (max-width: 480px) {
        font-size: 10px;
        height: 40px;
        margin-top: 15px;
      }
    }
  }
}

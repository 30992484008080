.FAQ {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.accordion {
  border: none;
  box-shadow: none;
  background-color: hsla(220, 41%, 96%, 1) !important;
  border-radius: 25px !important;
}

.summery {
  background-color: hsla(220, 41%, 96%, 1) !important;
  border-radius: 15px !important;
  padding: 5px 28px 5px 26px !important;
  
  @media (max-width: 920px) {
    padding: 20px 40px !important;
  }
  @media (max-width: 500px) {
    padding: 18px 20px !important;
  }

  .summeryContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .summeryTitle {
      font-family: HeadingNow;
      font-size: 13px;
      line-height: 22px;
      max-width: 95%;
      display: flex;
      text-transform: uppercase;
      color: var(--dark-blue);

      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 19px;
      }
    }

    .iconWrapper {
      color: hsla(220, 18%, 51%, 0.5);
      font-size: 16px;
      transition: 0.2s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.details {
  border: 0 !important;
  padding: 0 26px 20px 26px !important;

  @media (max-width: 920px) {
    padding: 0px 40px 30px 40px !important;
  }
  @media (max-width: 500px) {
    padding: 0px 20px 18px 20px !important;
  }

  .detailsContent {
    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    color: var(--dark-blue);
    line-height: 125%;

    @media (max-width: 500px) {
      font-size: 15px;
      line-height: 125%;
    }

    &>a{
        color: inherit;
    }
  }
}

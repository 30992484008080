@use "src/styles/mixins" as m;

.button {
  user-select: none;
  font-family: HeadingNow;
  text-transform: uppercase;
  color: white;
  height: 50px;
  max-height: 100%;
  background-color: var(--main-color);
  border-radius: 25px;
  padding: 0 31px;
  width: min-content;
  transition: 0.3s;
  transition-property: transform box-shadow;
  box-shadow: 0px 5px 15px transparent;
  font-size: inherit;

  @include m.desktop() {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 5px 15px rgba(255, 165, 59, 0.5);
    }
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 5px 15px transparent;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

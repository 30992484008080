@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-VariableFont_wght.ttf")
    format("truetype-variations");
}

@font-face {
  font-family: "HeadingNow";
  src: url("../fonts/HeadingNow-96BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "icomoon";
  src: url("../icons/fonts/icomoon.eot?mgieu1");
  src: url("../icons/fonts/icomoon.eot?mgieu1#iefix")
      format("embedded-opentype"),
    url("../icons/fonts/icomoon.ttf?mgieu1") format("truetype"),
    url("../icons/fonts/icomoon.woff?mgieu1") format("woff"),
    url("../icons/fonts/icomoon.svg?mgieu1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-global:before {
  content: "\e902";
}
.icon-balanceWrapped:before {
  content: "\e91c";
}
.icon-close:before {
  content: "\e91d";
}
.icon-check:before {
  content: "\e908";
}
.icon-remove:before {
  content: "\e916";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-medium:before {
  content: "\e913";
}
.icon-youtube:before {
  content: "\e915";
}
.icon-triangle:before {
  content: "\e909";
}
.icon-arrowDown:before {
  content: "\e90a";
}
.icon-back:before {
  content: "\e90b";
}
.icon-balance:before {
  content: "\e90c";
}
.icon-copy:before {
  content: "\e90d";
}
.icon-discord:before {
  content: "\e90e";
}
.icon-google .path1:before {
  content: "\e917";
  color: rgb(66, 133, 244);
}
.icon-google .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-google .path3:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-google .path4:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-lock:before {
  content: "\e918";
}
.icon-logOut:before {
  content: "\e919";
}
.icon-metaMask:before {
  content: "\e91a";
}
.icon-mission:before {
  content: "\e91b";
}
.icon-people:before {
  content: "\e90f";
}
.icon-settings:before {
  content: "\e911";
}
.icon-telegram:before {
  content: "\e912";
}
.icon-twitter:before {
  content: "\e914";
}
.icon-inventory:before {
  content: "\e900";
}
.icon-present:before {
  content: "\e901";
}

.progressBar {
  border-radius: 5px;
  height: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1px;

  @media (max-width: 480px) {
    height: 7.5px;
    border-radius: 3px;
  }

  .segment {
    height: 100%;
    width: 100%;
    background-color: hsla(222, 28%, 90%, 1);
    border-radius: 1px;
    position: relative;

    &:first-child {
      border-radius: 5px 1px 1px 5px;
    }

    @mixin coin {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
    }

    .smallCoin {
      @include coin;
      height: 49px;
      width: 49px;
      padding: 5px;

      @media (max-width: 480px) {
        height: 38px;
        width: 38px;
        padding: 3px;
      }
    }
    .bigCoin {
      @include coin;
      height: 49px;
      width: 49px;

      @media (max-width: 480px) {
        height: 38px;
        width: 38px;
      }
    }

    .fillWrapper {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 1px;

      &.first {
        border-radius: 5px 1px 1px 5px;
      }
    }

    .dayNumber {
      position: absolute;
      left: 100%;
      top: calc(100% + 24px);
      transform: translateX(-50%);
      font-weight: 800;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: hsla(220, 21%, 80%, 1);
      z-index: 3;
      white-space: nowrap;

      @media (max-width: 480px) {
        font-size: 9px;
        top: calc(100% + 18px);
      }
    }
  }

  .iconWrapper {
    background-color: hsla(222, 18%, 75%, 1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 15px;
    width: 26px;
    height: 26px;
    transition: 0.3s;
  }

  .bonusContainer {
    position: absolute;
    border-radius: 15px;
    background-color: hsla(220, 32%, 74%, 1);
    height: 35px;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    justify-content: center;
    bottom: calc(100% + 25px);
    left: 100%;
    transform: translateX(-50%);
    transition: 0.3s;
    white-space: nowrap;
    padding: 0 10px;

    &.passed {
      background-color: var(--main-color);

      .plus {
        display: none;
      }
    }

    .plus {
      font-size: 20px;
      font-weight: 600;
      line-height: 21.5px;
    }

    .balanceIcon {
      color: white;
      font-size: 19px;
      line-height: 21.5px;
    }

    .value {
      font-weight: 800;
      font-size: 15px;
      line-height: 21.5px;
    }

    @media (max-width: 480px) {
      border-radius: 13px;
      height: 29px;
      bottom: calc(100% + 19px);

      .plus {
        font-size: 17px;
      }

      .balanceIcon {
        font-size: 13px;
      }

      .value {
        font-size: 11px;
      }
    }
  }

  .fill {
    transition: 0.3s;
    position: absolute;
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;

    &.visible {
      width: 100%;
      background-color: var(--main-color);
    }
  }
}

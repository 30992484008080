.referralPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 950px) {
    gap: 20px;
  }
}

.topBanner {
  width: 100%;
  .image {
    width: 100%;
    object-fit: contain;
  }
}

.userStats {
  display: grid;
  grid-template-columns: minmax(350px, 403px) minmax(228px, 259px);
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 22px;
  grid-template-areas:
    "user tokens"
    "user tokens"
    "user invites"
    "pay invites";

  @media (max-width: 950px) {
    gap: 10px;
  }
  @media (max-width: 640px) {
    grid-template-columns: minmax(250px, 403px) minmax(228px, 259px);
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 49px;
    row-gap: 25px;
    column-gap: 15px;
    grid-template-areas:
      "user user"
      "tokens invites"
      "pay pay";
  }

  .user {
    grid-area: user;
    padding: 50px 31px 47px 47px;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 640px) {
      padding: 22px 23px 22px 20px;
    }

    .copyButton {
      position: absolute;
      right: 10px;
      background-color: transparent;
      color: hsla(220, 18%, 51%, 0.5);
      font-size: 16px;
      transition: 0.3s;

      @media (min-width: 760px) {
        &:hover {
          color: hsla(220, 18%, 61%, 0.5);
        }
      }
    }

    .avatarWrapper {
      width: 100px;
      height: 100px;
      border-radius: 40%;
      overflow: hidden;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 640px) {
        display: none;
      }
    }
    .name {
      padding-top: 20px;
      font-family: HeadingNow;
      font-size: 15px;
      color: var(--dark-blue);
      text-transform: uppercase;
      @media (max-width: 640px) {
        display: none;
      }
    }
    .email {
      padding-top: 12px;
      font-size: 18px;
      font-weight: 700;
      color: var(--dark-blue);
      @media (max-width: 640px) {
        display: none;
      }
    }
    .referralLinkTitle {
      font-family: HeadingNow;
      font-size: 18px;
      color: var(--dark-blue);
      text-transform: uppercase;
      @media (max-width: 460px) {
        font-size: 13px;
      }
    }
    .referralLink {
      position: relative;
      margin-top: 15px;
      color: var(--dark-blue-opacity);
      font-size: 19px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 13px 12px 13px 21px;
      border: 2px solid var(--main-color);
      border-radius: 20px;
      width: 100%;
      max-width: 296px;
      overflow-y: hidden;
      @media (max-width: 600px) {
        padding: 12px 18px 12px 19px;
        border-radius: 15px;
      }
    }
    .referralLinkValue {
      width: 100%;
      padding-right: 20px;
      color: var(--dark-blue);
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 500px) {
        font-size: 13px;
      }
    }
    .tweetButton {
      position: relative;
      margin-top: 20px;
      height: 50px;
      max-width: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: var(--twitter);
      color: white;
      border-radius: 25px;
      font-size: 23px;
      transition: 0.3s;

      &:active{
        transform: translateY(2px);
      }

      .tweetValue {
        font-family: HeadingNow;
        font-size: 15px;
        text-transform: uppercase;
      }
      @media (min-width: 760px) {
        &:hover{
          transform: translateY(-2px);
        }
      }
      @media (max-width: 600px) {
        height: 40px;
        max-width: 135px;
        font-size: 19px;

        .tweetValue {
          font-size: 10px;
        }
      }
    }
  }
  .panel {
    border-radius: 30px;
    padding: 22px 26px 20px 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 540px) {
      padding: 16.5px 11px 15px 12px;
      gap: 15px;
    }
    &.tokens {
      background-color: var(--blue);
      grid-area: tokens;
      .backgroundValue {
        color: var(--blue);
      }
    }
    &.invites {
      background-color: var(--main-color);
      grid-area: invites;
    }

    .title {
      font-family: HeadingNow;
      font-size: 15px;
      line-height: 125%;
      text-transform: uppercase;
      color: var(--light-color);
      z-index: 1;
      @media (max-width: 460px) {
        font-size: 11.25px;
      }
    }
    .valueContainer {
      z-index: 1;
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: flex-end;
      @media (max-width: 460px) {
        gap: 7.14px;
      }

      .balanceIconWrapper {
        position: relative;
        bottom: 2px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: var(--blue);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        font-size: 25px;
        @media (max-width: 460px) {
          width: 30px;
          height: 30px;
          font-size: 18px;
        }
      }

      .value {
        color: white;
        font-family: HeadingNow;
        font-size: 50px;
        @media (max-width: 460px) {
          font-size: 37.5px;
        }
      }
    }
    .backgroundValue {
      font-family: HeadingNow;
      z-index: 0;
      position: absolute;
      left: 60%;
      top: 40%;
      color: var(--main-color);
      text-shadow: -0 -3px 0 #ffffff, 0 -3px 0 #ffffff, -0 3px 0 #ffffff,
        0 3px 0 #ffffff, -3px -0 0 #ffffff, 3px -0 0 #ffffff, -3px 0 0 #ffffff,
        3px 0 0 #ffffff, -1px -3px 0 #ffffff, 1px -3px 0 #ffffff,
        -1px 3px 0 #ffffff, 1px 3px 0 #ffffff, -3px -1px 0 #ffffff,
        3px -1px 0 #ffffff, -3px 1px 0 #ffffff, 3px 1px 0 #ffffff,
        -2px -3px 0 #ffffff, 2px -3px 0 #ffffff, -2px 3px 0 #ffffff,
        2px 3px 0 #ffffff, -3px -2px 0 #ffffff, 3px -2px 0 #ffffff,
        -3px 2px 0 #ffffff, 3px 2px 0 #ffffff, -3px -3px 0 #ffffff,
        3px -3px 0 #ffffff, -3px 3px 0 #ffffff, 3px 3px 0 #ffffff,
        -3px -3px 0 #ffffff, 3px -3px 0 #ffffff, -3px 3px 0 #ffffff,
        3px 3px 0 #ffffff;
      transform: translate(-50%, -50%);
      font-size: 220px;
      opacity: 0.1;
      user-select: none;
      @media (max-width: 460px) {
        font-size: 165px;
      }
    }
  }

  .pay {
    grid-area: pay;
    width: 100%;
    background-color: var(--gray);
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: var(--dark-blue);
    font-size: 18px;
    max-width: 70%;

    @media (max-width: 680px) {
      width: 100%;
      max-width: 100%;
    }
    @media (max-width: 540px) {
      border-radius: 18px;
      font-size: 14px;
      gap: 9.37px;
    }

    .title {
      font-family: HeadingNow;
      font-size: 13px;
      text-transform: uppercase;
      @media (max-width: 600px) {
        font-size: 10px;
      }
    }
  }
}

.invite {
  background-color: white;
  padding: 41px 41px 39px 47px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  color: var(--dark-blue);
  @media (max-width: 640px) {
    padding: 30px 20px 30px 25px;
  }

  .title {
    font-family: HeadingNow;
    font-size: 14px;
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  .content {
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
}

.sideBanners {
  user-select: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: calc(var(--height, 100vh) - 120px - 50px - 30px);
  user-select: none;

  @media (max-width: 1310px) {
    height: 100%;
    gap: 10px;
  }
}

.timerWrapper {
  @media (max-width: 1310px) {
    display: none;
  }
}

.banner {
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  // height: 100%;
  position: relative;
  transition: 0.3s;

  @media (min-width: 770px) {
    &:hover {
      transform: translateY(-2px);
    }
  }

  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media (max-width: 1310px) {
      object-fit: cover;
    }
  }
}

.wrapper{
  display: flex;
  flex-direction: row;
  gap: 15px;

  @media (max-width: 390px) {
    flex-wrap: wrap;
    gap: 5px;
  }
}

.metaMask {
  border: 1px solid var(--main-color);
  background-color: transparent;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  height: 45px;
  width: 100%;
  max-width: 255px;
  gap: 9.19px;
  transition: 0.3s;
  transition-property: transform box-shadow;

  @media (min-width: 770px) {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 5px 15px rgba(255, 165, 59, 0.5);
    }
  }

  .value {
    font-weight: 800;
    font-size: 16px;
    color: var(--dark-blue);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 159px;
    text-transform: uppercase;
  }

  .iconWrapper {
    color: var(--main-color);
    font-size: 17px;
  }

  &.metaMaskNoWallet {
    background-color: var(--main-color);
    padding-right: 17px;

    .iconWrapper {
      color: white;
    }

    .value {
      text-overflow: unset;
      max-width: unset;
      text-transform: uppercase;
      color: white;
      font-family: HeadingNow;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

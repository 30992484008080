@use "src/styles/mixins" as m;

.giftBlock {
  width: 175px;
  min-height: 197px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  background: linear-gradient(15.72deg, #7845ab 5.98%, #9f66d8 95.82%);
  border-radius: 30px;

  @media (max-width: 650px) {
    width: 105px;
    border-radius: 18px;
    gap: 7px;
    padding: 7px;
    min-height: 118px;
  }

  .background {
    position: absolute;
    width: 132px;
    height: 150px;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;

    img {
      object-fit: contain;
    }

    @keyframes floating {
      0% {
        transform: translateX(-50%) translateY(-2px);
      }
      100% {
        transform: translateX(-50%) translateY(2px);
      }
    }

    @media (max-width: 650px) {
      width: 87px;
      height: 95px;
    }
  }

  .button {
    z-index: 2;
    background-color: hsla(220, 13%, 91%, 1);
    color: var(--dark-blue);
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    transition: 0.3s;

    @include m.desktop() {
      &:hover {
        transform: initial;
        box-shadow: initial;
      }
    }

    &.active {
      background-color: var(--main-color);
      color: white;

      @include m.desktop() {
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0px 5px 15px var(--main-color-opacity2);
        }
      }
    }

    @media (max-width: 650px) {
      font-size: 7.8px;
      max-height: 30px;
    }
  }

  .dayNumber {
    color: white;
    letter-spacing: 0.02em;
    font-weight: 800;
    font-size: 15px;

    @media (max-width: 650px) {
      font-size: 9px;
    }
  }
}

.wrapper {
  &:hover {
    .iconBefore {
      color: white;
    }
    .iconAfter {
      color: white;
    }
  }
}

div.select {
  width: 100%;
  color: var(--dark-blue);
  letter-spacing: 0.02em;
  font-family: Manrope;
  font-weight: 800;
  font-size: 16px;
  transition: 0.3s;
  padding: 0;
  height: 100%;
}

li.selectItem {
  padding: 7.5px 30px;
  color: var(--dark-blue);
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  transition: 0.2s;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuWrapper {
  border-radius: 20px !important;
  box-shadow: 0px 0px 15px -3px hsla(0, 0, 5%, 0.2) !important;
  margin-top: 10px !important;
}

.menu {
  background-color: white;
}

.value {
  padding-left: 5px;
}

.valueContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .iconBefore {
    font-size: 21px;
    color: var(--main-color);
    z-index: 2;
    transition: 0.3s;
  }

  .iconAfter {
    color: var(--dark-blue);
    font-size: 9px;
    transition: 0.3s;

    &.open {
      transform: scale(-1);
    }
  }
}

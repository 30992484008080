@use "/src/styles/variables" as v;

.header {
  position: relative;
  width: 100%;
  height: v.$headerHeight;

  @media (max-width: 920px) {
    z-index: 2000;
    height: v.$headerHeightTablet;
  }
  @media (max-width: 400px) {
    height: v.$headerHeightMobile;
  }
}

.fixedHeader {
  top: 0;
  left: 0;
  position: fixed;
  background-color: white;
  width: 100%;
  height: v.$headerHeight;
  display: flex;
  z-index: 100;
  @media (max-width: 920px) {
    height: v.$headerHeightTablet;
  }
  @media (max-width: 400px) {
    height: v.$headerHeightMobile;
  }
}

.container {
  width: 100%;
  max-width: 1560px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: unset;
  }

  .leftPart {
    display: flex;
    flex-direction: row;
    gap: 45px;
    align-items: center;

    .logoWrapper {
      width: 98px;
      height: 39px;
      position: relative;
      @media (max-width: 400px) {
        height: 75.36px;
        height: 30px;
      }
      .logo {
        width: 100%;
        height: 100%;
      }
    }

    .changeLanguage{
      min-width: 105px;
      height: 45px;

      @media (max-width: 920px) {
        display: none;
      }
    }
  }

  .rightPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 920px) {
      justify-content: flex-end;
    }

    .metaMaskWrapper {
      @media (max-width: 920px) {
        display: none;
      }
    }

    .balance {
      border: 1px solid hsla(32, 100%, 62%, 1);
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 5px;
      height: 45px;
      padding: 0 22px;
      transition: 0.3s;
      user-select: none;

      .value {
        font-weight: 800;
        font-size: 16px;
        color: var(--dark-blue);
        transition: 0.3s;
      }

      .balanceIcon {
        color: var(--main-color);
        font-size: 23px;
        transition: 0.3s;
      }

      @media (min-width: 770px) {
        &:hover {
          background-color: var(--main-color);
          color: white;

          .balanceIcon {
            color: white;
          }
          .value {
            color: white;
          }
        }
      }

      @media (max-width: 770px) {
        height: 27px;
        padding: 0 7px;

        .value {
          font-size: 15px;
        }

        .balanceIcon {
          font-size: 18px;
        }
      }
    }

    .userWrapper {
      @media (max-width: 920px) {
        display: none;
      }
    }

    .user {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      background-color: transparent;

      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 77px;
        color: var(--dark-blue);
        white-space: nowrap;

        @media (max-width: 1130px) {
          display: none;
        }
      }

      .avatarWrapper {
        width: 50px;
        height: 50px;
        border-radius: 40%;
        overflow: hidden;

        .avatar {
          width: 100%;
          height: 100%;
        }
      }

      .triangle {
        font-size: 10px;
        color: hsla(220, 18%, 51%, 0.5);
        transition: 0.3s;
      }

      &.open {
        .triangle {
          transform: scale(-1);
        }
      }
    }
  }
}
.menu {
  & .MuiPaper-root {
    background-color: black !important;
  }
  & .MuiMenu-list {
    background-color: black !important;
  }

  .menuItem {
    display: flex;
    flex-direction: row;
    gap: 12px;
    color: var(--dark-blue) !important;
    font-size: 20px;
    background-color: transparent;
    position: relative;
    width: 100%;
    padding: 30px 20px 12.25px 52px;

    .iconWrapper {
      color: var(--dark-blue-opacity);
      transition: 0.3s;
    }

    &:hover {
      .iconWrapper {
        color: var(--dark-blue);
      }
    }

    &:nth-child(2) {
      padding: 12.25px 20px 30px 52px;
    }

    .title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

div.select {
  width: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue-opacity);
  border-radius: 20px;
  height: 45px;
  padding: 0px 13px 0 20px;
  max-width: 417px;
  letter-spacing: 0.02em;

  @media (max-width: 480px) {
    margin-top: 15px;
    height: 40px;
  }

  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  transition: 0.3s;
  letter-spacing: 0.02em;
  max-width: 317px;
  padding: 0;
}

li.selectItem {
  padding: 10px 20px;
  color: var(--dark-blue);
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  transition: 0.2s;
}

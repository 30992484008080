.switch {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: hsla(220, 13%, 91%, 0.5);
  border-radius: 20px;

  .tab {
    position: relative;
    font-family: HeadingNow;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--dark-blue);
    height: 100%;
    width: 50%;
    z-index: 2;
    transition: 0.2s;

    &.currentTab {
      color: white;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }

  .fill {
    z-index: 1;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.2s;
    position: absolute;
    background-color: hsla(32, 100%, 62%, 1);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15),
      0px 5px 20px rgba(255, 165, 59, 0.5);
    border-radius: 20px;
  }
}
